import {
    FormActionTypes,
    IOne2OneBuyer,
    TranslationsObject,
} from "@genericTypes/sharedTypes";
import React, { useMemo } from "react";
import { useDispatch, useFormState } from "../formReducer/FormReducer";
import BuyerCard from "./BuyerCard";
import buttonStyles from "../step/styles.module.scss";
import styles from "./styles.module.scss";
import Spinner from "@components/shared/spinner";
import { useQuery } from "@tanstack/react-query";
import { translate } from "@helpers/translationObjectFormatter";

const SecondRoundBuyers = ({
    buyers,
    btnText = "Submit",
    buttonLoading = false,
}: {
    buyers: IOne2OneBuyer[];
    btnText: string;
    buttonLoading: boolean;
}) => {
    const { data: translations } = useQuery<TranslationsObject>([
        "translations",
    ]);
    const dispatch = useDispatch();
    const [formState] = useFormState();
    const buttonText =
        btnText ||
        (formState.formResponse?.steps[formState.currentStep]
            .buttonText as string);

    const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch({
            type: FormActionTypes.SET_SECOND_ROUND_BUYERS,
            payload: [
                ...buyers.map((item) => ({
                    ...item,
                    selected: e.target.checked,
                })),
            ],
        });
    };

    const areAllSelected = useMemo(() => {
        return buyers.every((item) => item.selected);
    }, [formState?.secondRoundBuyers]);

    const disabled = useMemo(() => {
        return buyers
            .filter(
                (item) => !item.displayName.toLowerCase().includes("several"),
            )
            .every((item) => !item.selected);
    }, [formState.secondRoundBuyers]);

    return (
        <div className="flex flex-col gap-5 w-full">
            <span className="font-extrabold text-2xl">
                {translate(translations, "several_form_second_round_title")}
            </span>
            <span className="font-normal text-lg">
                {translate(translations, "several_form_second_round_sub_title")}
            </span>
            <div className="flex items-center gap-2">
                <input
                    id="select_all"
                    type="checkbox"
                    style={{ width: "15px", height: "15px" }}
                    onChange={(e) => handleSelectAll(e)}
                    checked={areAllSelected}
                />
                <label htmlFor="select_all">
                    {translate(
                        translations,
                        "several_form_second_round_select_all_label",
                    )}
                </label>
            </div>
            {buyers.map((buyer) => (
                <BuyerCard
                    buyer={buyer}
                    key={buyer.buyerId}
                    isSelected={buyer.selected}
                />
            ))}
            <div className="w-full">
                <button
                    className={`${buttonStyles["nextBtn"]} ${styles["nextBtn"]} h-[3rem] font-bold`}
                    type="submit"
                    value={buttonText}
                    id="submitBtn"
                    data-tf-element-role="submit"
                    disabled={buttonLoading || disabled}
                >
                    {buttonLoading ? (
                        <Spinner size={25} color="white" success={false} />
                    ) : (
                        buttonText
                    )}
                </button>
            </div>
        </div>
    );
};

export default SecondRoundBuyers;
