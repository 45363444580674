import React, { useEffect, useState } from "react";
import Modal from "@components/shared/modal";
import { showModal } from "@store/categoryStore";
import { useAtom } from "jotai";
import Portal from "src/portals/Portal";
import { useDispatch, useFormState } from "../formReducer/FormReducer";
import { FormActionTypes, IOne2OneBuyer } from "@genericTypes/sharedTypes";
// import { IOne2OneBuyer } from "@genericTypes/sharedTypes";

const SecondRoundTCPA = ({
    // buyers,
    tcpaMessage,
    btnText = "Get My Free Quote Now",
    domainAsBuyer,
}: {
    // buyers: IOne2OneBuyer[];
    tcpaMessage: string;
    btnText?: string;
    domainAsBuyer: IOne2OneBuyer;
}) => {
    const [modalVisibility, setModalVisibility] = useAtom(showModal);
    const [formState] = useFormState();
    const dispatch = useDispatch();
    const [tcpaModalContent, setTcpaModalContent] = useState<string | null>(
        null,
    );
    // const selectedBuyers = useMemo(() => {
    //     return [
    //         formState.firstRoundBuyers
    //             .filter((item) => item.selected)
    //             ?.map((item) => item.displayName || item.buyerName),
    //         buyers
    //             .filter((item) => item.selected)
    //             .map((item) => item.displayName || item.buyerName),
    //     ].join(",");
    // }, [formState.firstRoundBuyers, buyers]);

    useEffect(() => {
        const partnersEl = document.getElementById("tcpa-Modal");
        const popupContent = partnersEl?.getElementsByTagName("span")[0];
        popupContent?.remove();

        if (popupContent) {
            setTcpaModalContent(popupContent?.innerHTML.trim());
        }

        partnersEl?.addEventListener("click", () => {
            setModalVisibility(true);
        });

        if (!formState.domainConsent) {
            dispatch({
                type: FormActionTypes.SET_SECOND_ROUND_BUYERS,
                payload: [...formState.secondRoundBuyers, domainAsBuyer],
            });
        }

        return () => {
            partnersEl?.removeEventListener("click", () => {
                setModalVisibility(true);
            });
        };
    }, []);

    return (
        <>
            {modalVisibility && (
                <Portal>
                    <Modal
                        showOuterClose={false}
                        closeIconClassName="absolute"
                        outerClassName="flex items-center justify-center h-[90vh] m-auto"
                        className="min-w-[400px] !h-fit p-8 rounded relative"
                        title="Our Partners"
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: tcpaModalContent as string,
                            }}
                        />
                    </Modal>
                </Portal>
            )}
            <input type="hidden" id="leadid_tcpa_disclosure"></input>
            <label htmlFor="leadid_tcpa_disclosure">
                <div
                    data-tf-element-role="consent-language"
                    className="text-[9px] mt-3 text-[gray]"
                    id="tcpa-message"
                    dangerouslySetInnerHTML={{
                        __html: tcpaMessage
                            .replace(
                                "${btnText}",
                                `<span data-tf-element-role="submit-text" \>${btnText}</span>`,
                            )
                            .replace(
                                "from the following: ${buyers}",
                                "from the above selected buyers",
                            ),
                    }}
                />
            </label>
        </>
    );
};

export default SecondRoundTCPA;
