import { gql } from "graphql-request";
export const FORM_FUSION_THANK_YOU_OFFERS = gql`
    query getFormFusionThankYouOffers(
        $categoryId: Int
        $language: String!
        $domainId: Int
    ) {
        allDomainOffers(
            domainId: $domainId
            categoryId: $categoryId
            orderByDesc: weight
        ) {
            placements
            offers(isDeleted: false, isActive: true) {
                title
                id
                name
                slug
                attributes(isDeleted: false) {
                    value
                    id
                    hiddenAttributes {
                        value
                        name
                    }
                    baseAttribute {
                        name
                        value
                        hidden
                        type
                    }
                }
                url(isActive: true, isDeleted: false, languageEq: $language) {
                    isMain
                    main {
                        url
                    }
                    url
                }
                logo {
                    path
                    fullPath
                }
                workingHours {
                    id
                    isActive
                    timeZone
                    schedule
                }
            }
        }
    }
`;
