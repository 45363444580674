import React, { useEffect, useState } from "react";
import { FormActionTypes, IOne2OneBuyer } from "@genericTypes/sharedTypes";
import Modal from "@components/shared/modal";
import { showModal } from "@store/categoryStore";
import { useAtom } from "jotai";
import Portal from "src/portals/Portal";
import { useDispatch, useFormState } from "../formReducer/FormReducer";

const TCPA = ({
    tcpaMessage,
    btnText = "Get My Free Quote Now",
}: {
    tcpaMessage: string;
    btnText?: string;
}) => {
    const dispatch = useDispatch();
    const [formState] = useFormState();
    const [modalVisibility, setModalVisibility] = useAtom(showModal);
    const [tcpaModalContent, setTcpaModalContent] = useState<string | null>(
        null,
    );
    const buttonText =
        btnText ||
        (formState.formResponse?.steps[formState.currentStep]
            .buttonText as string);

    useEffect(() => {
        //? TCPA MODAL LOGIC
        const partnersEl = document.getElementById("tcpa-Modal");
        const popupContent = partnersEl?.getElementsByTagName("span")[0];
        popupContent?.remove();

        if (popupContent) {
            setTcpaModalContent(popupContent?.innerHTML.trim());
        }

        partnersEl?.addEventListener("click", () => {
            setModalVisibility(true);
        });

        //? TCPA CHECKBOXES LOGIC
        const inputs = document.querySelectorAll(
            '[data-tf-element-role*="consent-opted-advertiser-input"]',
        );
        inputs.forEach((input) => {
            input.addEventListener("change", () => handleCheckboxChange(input));
        });

        return () => {
            partnersEl?.removeEventListener("click", () => {
                setModalVisibility(true);
            });

            inputs.forEach((input) => {
                input.removeEventListener("change", () =>
                    handleCheckboxChange(input),
                );
            });
        };
    }, []);

    const handleCheckboxChange = (input: Element) => {
        const name = input.getAttribute("data-tf-element-role");
        const element = document.querySelector(
            `[data-tf-element-role="${name as string}"]`,
        );
        const checked = element?.getAttribute("checked");
        if (element) {
            element.setAttribute(
                "checked",
                checked === "true" ? "false" : "true",
            );
        }

        const checkboxes = document.querySelectorAll(
            '[data-tf-element-role*="consent-opted-advertiser-input"]',
        );

        const updatedBuyers: IOne2OneBuyer[] = [];

        checkboxes.forEach((checkbox) => {
            const checked = checkbox?.getAttribute("checked");
            const id = checkbox?.getAttribute("id");
            const currentBuyer = formState.firstRoundBuyers.find((buyer) => {
                return buyer.buyerId === id;
            });
            if (currentBuyer) {
                updatedBuyers.push({
                    ...currentBuyer,
                    selected: checked === "true",
                });
            }
        });

        const domain = updatedBuyers.find((item) =>
            item.displayName.toLowerCase().includes("several"),
        );

        const isDisabled = updatedBuyers
            .filter(
                (item) => !item.displayName.toLowerCase().includes("several"),
            )
            .every((item) => item.selected === false);

        dispatch({
            type: FormActionTypes.SET_FIRST_ROUND_BUYERS,
            payload: updatedBuyers,
        });
        dispatch({
            type: FormActionTypes.SET_BUTTON_DISABLED,
            payload: isDisabled,
        });
        dispatch({
            type: FormActionTypes.SET_DOMAIN_CONSENT,
            payload: !!domain?.selected,
        });
    };

    return (
        <>
            {modalVisibility && (
                <Portal>
                    <Modal
                        showOuterClose={false}
                        closeIconClassName="absolute"
                        outerClassName="flex items-center justify-center h-[90vh] m-auto"
                        className="min-w-[400px] !h-fit p-8 rounded relative"
                        title="Our Partners"
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: tcpaModalContent as string,
                            }}
                        />
                    </Modal>
                </Portal>
            )}
            <input type="hidden" id="leadid_tcpa_disclosure"></input>
            <label htmlFor="leadid_tcpa_disclosure">
                <div
                    data-tf-element-role="consent-language"
                    className="text-[9px] mt-3 text-[gray]"
                    id="tcpa-message"
                    dangerouslySetInnerHTML={{
                        __html: tcpaMessage
                            ?.replace(
                                /\$\{btnText\}/g,
                                `\<span data-tf-element-role="submit-text" \>${buttonText}\<\/span\>`,
                            )
                            .replace(
                                /\$\{buyers\}/g,
                                `${formState.firstRoundBuyers
                                    ?.map(
                                        (buyer) =>
                                            `<span  style="display: inline-block"><span style="font-weight: 500; display:flex;flex-wrap:nowrap;position: relative;top: 4px;align-items: center;" data-tf-element-role="consent-opted-advertiser-name-${
                                                buyer.buyerId
                                            }"><input type="checkbox" id=${
                                                buyer.buyerId
                                            } style="margin-right:5px;line-break: anywhere;accent-color: gray;"  checked="true" data-tf-element-role="consent-opted-advertiser-input-${
                                                buyer.buyerId
                                            }" />${
                                                buyer.displayName ??
                                                buyer.buyerName
                                            }</span></span>`,
                                    )
                                    .join(", ")}`,
                            ),
                    }}
                />
            </label>
        </>
    );
};

export default TCPA;
