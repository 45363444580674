import TopBuyerItem from "@components/category/topBuyerItem";
import Spinner from "@components/shared/spinner";
import { AllFieldsDependency } from "@genericTypes/form-store-types";
import { GQLCategories, GQLOffers } from "@gql/types/gql";
import { decodeFieldsFromText } from "@helpers/formHelpers/decodeFieldsFromText";
import { getFromStorage, saveToStorage } from "@helpers/storageHelper";
import {
    formsAtom,
    removeFormAtom,
    selectedCategoryAtom,
} from "@store/formStore";
import { useAtom } from "jotai";
import React, { useEffect, useState, useCallback } from "react";
import { Fade } from "react-awesome-reveal";
import Widget from "./widget";
import { useDispatch } from "../formReducer/FormReducer";
import { FormActionTypes } from "@genericTypes/sharedTypes";
export interface dependenciesFromStorage {
    [formId: number]: AllFieldsDependency;
}

export interface vars {
    id: number;
    slug: string;
}

const ThankYou = ({
    message,
    formId,
    shouldRemoveForm,
    thankYouOffers,
}: {
    message: string;
    formId: number;
    shouldRemoveForm: boolean;
    thankYouOffers: GQLOffers[];
}) => {
    const [successMessageArray, setSuccessMessageArray] = useState<string[]>(
        [],
    );

    const [widgets, setWidgets] = useState<string[] | null>([]);
    const dispatch = useDispatch();
    const [form] = useAtom(formsAtom);
    const [, removeForm] = useAtom(removeFormAtom);
    const [selectedCategory, setSelectedCategory] =
        useAtom(selectedCategoryAtom);
    const [thankYouMessage, setThankYouMessage] = useState(message);
    const [loading, setLoading] = useState(true);

    const removeFormDependencies = () => {
        const loadedData = getFromStorage("local", "dependencies");
        if (loadedData && loadedData.length) {
            const parsedLoadedData: dependenciesFromStorage = JSON.parse(
                loadedData,
            ) as dependenciesFromStorage;

            if (parsedLoadedData && parsedLoadedData[formId]) {
                delete parsedLoadedData[formId];
            }
            saveToStorage(
                "local",
                "dependencies",
                JSON.stringify(parsedLoadedData),
            );
        }
    };

    useEffect(() => {
        setLoading(true);
        if (formId && form) {
            setThankYouMessage(decodeFieldsFromText(message, form, formId));
            if (shouldRemoveForm) {
                dispatch({
                    type: FormActionTypes.SET_SHOW_BUYERS_LIST,
                    payload: false,
                });
                dispatch({
                    type: FormActionTypes.SET_FIRST_ROUND_BUYERS,
                    payload: [],
                });
                dispatch({
                    type: FormActionTypes.SET_SECOND_ROUND_BUYERS,
                    payload: [],
                });
                dispatch({
                    type: FormActionTypes.SET_TEMP_SECOND_ROUND_BUYERS,
                    payload: [],
                });
                removeFormDependencies();
                removeForm(formId);
                setSelectedCategory(null);
            }
        }
        setLoading(false);
    }, []);

    const getWidgetType = (widgetTag: string) => {
        const typeRegex = /type=(\w+)/;
        const typeMatch = widgetTag.match(typeRegex);
        if (typeMatch) {
            const widgetType = typeMatch[1];
            return widgetType;
        }
        return "notFound";
    };

    const extractWidgets = useCallback((successMessage: string) => {
        const successMessageArrayAfterSplitting = successMessage.split(
            /\[widget type=[a-zA-Z0-9]+\]/i,
        );
        const widgetRegex = /\[widget type=[a-zA-Z0-9]+\]/gi;
        const widgets = successMessage.match(widgetRegex);
        setWidgets(widgets ? widgets : null);
        setSuccessMessageArray(successMessageArrayAfterSplitting);
    }, []);

    useEffect(() => {
        extractWidgets(thankYouMessage);
    }, [thankYouMessage]);

    return (
        <div>
            {loading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <Spinner size={30} color="#107fd4" success={false} />
                </div>
            ) : (
                <div className="flex flex-col justify-center items-center w-full gap-9">
                    {(widgets?.length as number) > 0 ? (
                        <Fade>
                            <div className="m-auto pt-0">
                                {successMessageArray.map((content, index) => {
                                    return (
                                        <div key={index}>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: content,
                                                }}
                                            ></div>
                                            {widgets?.[index] ? (
                                                <Widget
                                                    type={getWidgetType(
                                                        widgets[index],
                                                    )}
                                                    thankYouOffers={
                                                        thankYouOffers
                                                    }
                                                    selectedCategory={
                                                        selectedCategory as GQLCategories
                                                    }
                                                />
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </Fade>
                    ) : (
                        <>
                            <Fade>
                                <div
                                    className="m-auto pt-0"
                                    dangerouslySetInnerHTML={{
                                        __html: thankYouMessage,
                                    }}
                                />
                            </Fade>
                            <div className="max-w-[500px] sm:max-w-[750px] gap-2 flex flex-row justify-center sm:flex-row flex-wrap">
                                {thankYouOffers.map(
                                    (offer) =>
                                        offer && (
                                            <Fade
                                                key={
                                                    offer.id ??
                                                    offer.description
                                                }
                                            >
                                                <TopBuyerItem
                                                    vendor={offer}
                                                    categoryId={
                                                        selectedCategory?.id ??
                                                        -1
                                                    }
                                                    featured={false}
                                                    hideReview={true}
                                                    imageClassName={
                                                        "!w-[90px] sm:!w-[100px] !h-[90px] sm!h-[100px]"
                                                    }
                                                    containerStyle={
                                                        "w-[141px] sm:w-[180px] h-[150px] sm:h-[190px]"
                                                    }
                                                />
                                            </Fade>
                                        ),
                                )}
                            </div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default ThankYou;
