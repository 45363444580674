import { FormActionTypes, IOne2OneBuyer } from "@genericTypes/sharedTypes";
import React from "react";
import styles from "./styles.module.scss";
import { useDispatch, useFormState } from "../formReducer/FormReducer";

const BuyerCard = ({
    buyer,
    isSelected,
}: {
    buyer: IOne2OneBuyer;
    isSelected: boolean;
}) => {
    const dispatch = useDispatch();
    const [formState] = useFormState();

    const handleBuyerClick = (buyer: IOne2OneBuyer) => {
        dispatch({
            type: FormActionTypes.SET_SECOND_ROUND_BUYERS,
            payload: [
                ...formState.secondRoundBuyers.map((item) => {
                    if (item.buyerId === buyer.buyerId) {
                        return {
                            ...item,
                            selected: !isSelected,
                        };
                    }
                    return item;
                }),
            ],
        });
    };

    return (
        <div
            className={`flex items-center justify-between bg-white ${styles.buyer} ${styles["selected-buyer"]}`}
            data-selected={isSelected}
            onClick={() => handleBuyerClick(buyer)}
        >
            <div className="flex items-center gap-2">
                <input
                    type="checkbox"
                    style={{ width: "15px", height: "15px" }}
                    checked={isSelected}
                    data-tf-element-role={`consent-opted-advertiser-input-${buyer.buyerId}`}
                />
                <label
                    data-tf-element-role={`consent-opted-advertiser-name-${buyer.buyerId}`}
                >
                    {buyer.displayName || buyer.buyerName}
                </label>
            </div>
            {buyer.logo && (
                <div className="w-full max-w-[60px]">
                    <img
                        src={buyer.logo}
                        alt={buyer.displayName || buyer.buyerName}
                        width={200}
                    />
                </div>
            )}
        </div>
    );
};

export default BuyerCard;
