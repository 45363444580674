/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, {
    ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { useForm } from "react-hook-form";
import styles from "./style.module.scss";
import { ProgressBar } from "../progressBar/index";
import { useQuery } from "@tanstack/react-query";
import { getCategorySlug } from "@lib/sharedUtils";
import {
    formsAtom,
    formJsonAtom,
    initFieldsDependency,
    initSelectLoading,
    showOtpAtom,
    otpCodeAtom,
    AllConditionalLogicFieldsAtom,
    addFieldAtom,
    selectedCategoryAtom,
    removeFormAtom,
} from "@store/formStore";
import { useAtom, useAtomValue } from "jotai";
import {
    ErrorsAfterSubmit,
    ExpandableFieldMeta,
    Field,
    FieldConditionalLogic,
    FormActionTypes,
    FormStep,
    ICustomDomain,
    IOne2OneBuyer,
    SubmitLeadData,
} from "../../../../src/genericTypes/sharedTypes";
import { DomainForm } from "pages/api/domainForm";
import { stepValidator } from "@helpers/formHelpers/step-validator";
import { getUserMeta } from "@helpers/user-meta";
import { submitLeadForm } from "src/api/submitLead";
import Step from "./step";
import ThankYou, { dependenciesFromStorage } from "./thankYou";
import ErrorsFieldsAfterSubmit from "./errorFieldsAfterSubmit";
import {
    AllForms,
    ErrorField,
    FormErrors,
} from "@genericTypes/form-store-types";
import { useRouter } from "next/router";
import Spinner from "../spinner";
import {
    GQLCategoryByIdQuery,
    GQLCrossSellingDomainFormQuery,
    GQLOffers,
} from "@gql/types/gql";
import Otp from "./otp";
import Portal from "src/portals/Portal";
import FormModal from "../modal/formModal";
import { Fade } from "react-awesome-reveal";
import { getFromStorage, saveToStorage } from "@helpers/storageHelper";
import FormMainLabel from "./formMainLabel";
import { getClicksSearchList } from "src/api/clicks";
import InsuranceOffer, { ClickOffer } from "./insuranceOffer";
import { useDispatch, useFormState } from "./formReducer/FormReducer";
import { getAccessToken } from "@lib/shared/cookies";
import { fetchThankOffersQuery } from "@helpers/initial-call";
import { fetchLeadBuyers } from "src/api/buyers";
import FirstRoundTCPA from "./TCPA/FirstRoundTCPA";
import SecondRoundBuyers from "./secondRoundBuyers";
import SecondRoundTCPA from "./TCPA/SecondRoundTCPA";
const locale = process.env.NEXT_PUBLIC_DEFAULT_LANG;
const getInitState = (form: DomainForm | undefined) => {
    const allFieldsValue: { [key: string]: string[] | string | number } = {};
    form?.steps?.forEach((step) => {
        step.fields.forEach((field) => {
            allFieldsValue[field?.origin?.codeName] =
                field.fieldType === "checkbox"
                    ? ([] as string[])
                    : field.defaultValue
                    ? field.defaultValue
                    : "";
        });
    });
    return allFieldsValue;
};

const stepsOrderThatHaveError = new Set<number>();
const fieldsThatSkippedValidations: string[] = [];

export default function Form({
    shouldSplit = false,
    formData = null,
    crossSellingProp,
}: {
    shouldSplit?: boolean;
    formData?: GQLCategoryByIdQuery | null;
    crossSellingProp?: GQLCrossSellingDomainFormQuery | null;
}): ReactElement {
    const router = useRouter();
    const categorySlug = useQuery({
        queryKey: ["activeFormCategorySlug"],
    });
    const { data: categoryId } = useQuery<number | null>({
        queryKey: ["activeFormCategoryId"],
    });
    const categorySlugFromUrl =
        router.asPath.split("/")[1] === "blog" ||
        router.asPath.split("/")[1] === "news"
            ? categorySlug.data
            : getCategorySlug(router.asPath.replace(/#.*$/, "").split("/")[1]);

    const { data } = useQuery<GQLCategoryByIdQuery | undefined>({
        queryKey: ["activeForm", categorySlugFromUrl],
    });

    const tcpaMessageReactQuery = useQuery({
        queryKey: ["tcpaMessage"],
    });

    const crossSelling = useQuery<GQLCrossSellingDomainFormQuery>({
        queryKey: ["crossSelling"],
    });
    const { data: domainInfo } = useQuery<ICustomDomain, Error>({
        queryKey: ["domain"],
    });
    const { data: isOneToOneConsent } = useQuery<boolean, Error>({
        queryKey: ["isOneToOneConsent"],
    });

    const dispatch = useDispatch();
    const [formState] = useFormState();
    const [, removeForm] = useAtom(removeFormAtom);

    const domainAsBuyer: IOne2OneBuyer = {
        displayName:
            (domainInfo?.domain.niceName as string) ||
            (domainInfo?.domain?.name as string),
        buyerName:
            (domainInfo?.domain.niceName as string) ||
            (domainInfo?.domain?.name as string),
        buyerId: `${domainInfo?.domain.id as number}`,
        selected: true,
        logo: domainInfo?.domain.logo?.fullPath as string,
    };

    // ATOMS
    const form: AllForms = useAtomValue(formsAtom);
    // const [formId, setFormId] = useAtom(formIdAtom);
    const [, addFieldToFormState] = useAtom(addFieldAtom);
    const [, setFromJson] = useAtom(formJsonAtom);
    const [dependencies, setDependencies] = useAtom(initFieldsDependency);
    const [, setShowOtp] = useAtom(showOtpAtom);
    const [, setSelectLoading] = useAtom(initSelectLoading);
    const [otpCode] = useAtom(otpCodeAtom);
    const [conditionalLogic, setConditionalLogic] = useAtom(
        AllConditionalLogicFieldsAtom,
    );
    const [selectedCategory, setSelectedCategory] =
        useAtom(selectedCategoryAtom);

    // STATES
    const [timer, setTimer] = useState(0);

    // INITIALIZERS
    const {
        register,
        handleSubmit,
        control,
        setValue,
        watch,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        defaultValues: getInitState(formState.formResponse as DomainForm),
    });
    const jornayaIdRef = useRef(null);
    const jornayaIdElement: JSX.Element = useMemo(() => {
        return (
            <input
                id="leadid_token"
                name="universal_leadid"
                type="hidden"
                value=""
                ref={jornayaIdRef}
            />
        );
    }, []);

    function initDependencies() {
        if (Object.keys(dependencies).length === 0) {
            formState.formResponse?.steps?.map((step) => {
                step?.fields?.map((field) => {
                    field?.origin?.dataDependency?.dependency?.map((dep) => {
                        setDependencies({
                            codeName: field?.origin?.codeName,
                            isLoading: false,
                            formId: formState.formId,
                            dependency: {
                                dependsOn: dep.fieldCodeName,
                                value:
                                    (form?.[formState.formId]?.data?.[
                                        dep.fieldCodeName
                                    ]?.value as string) ?? "",
                                type: dep.dependentOn as string,
                                id:
                                    (form?.[formState.formId]?.data?.[
                                        dep.fieldCodeName
                                    ]?.id as string) ?? "",
                            },
                        });
                        if (
                            field.fieldType === "select" ||
                            field.origin.fieldType === "select"
                        ) {
                            setSelectLoading({
                                codeName: field?.origin?.codeName,
                                loading: false,
                                formId: formState.formId,
                                id: form?.[formState.formId]?.data?.[
                                    dep.fieldCodeName
                                ]?.id as string,
                            });
                        }
                    });
                });
            });
        }
    }

    function initExpandableFields() {
        dispatch({
            type: FormActionTypes.SET_REGULATIONS,
            payload: null,
        });
        const formId = formState.formId;
        formState.formResponse?.steps?.map((step) => {
            step.fields.map((field) => {
                let metaKeys: string[] = [];
                let isExpandableField: boolean = false;
                let hasRegulations: boolean = false;
                if (
                    (field?.meta && field.meta[0]) ||
                    (field?.origin?.meta && field?.origin?.meta[0])
                ) {
                    metaKeys = field.meta
                        ? Object.values(field.meta[0])
                        : field?.origin.meta
                        ? Object.values(field?.origin?.meta[0])
                        : [];
                    // check if field has expandable meta key
                    isExpandableField =
                        Array.isArray(metaKeys) && metaKeys.length
                            ? metaKeys[0] === "expandable"
                                ? true
                                : false
                            : false;
                    hasRegulations =
                        Array.isArray(metaKeys) && metaKeys.length
                            ? metaKeys[0] === "stateRegulation"
                                ? true
                                : false
                            : false;
                }
                if (isExpandableField) {
                    const expandableFields = JSON.parse(
                        metaKeys[1] ?? {},
                    ) as ExpandableFieldMeta;
                    const expandableFieldPayload = {
                        [formId]: expandableFields,
                    };
                    dispatch({
                        type: FormActionTypes.SET_EXPANDABLE_FIELD,
                        payload: expandableFieldPayload,
                    });
                } else if (hasRegulations) {
                    dispatch({
                        type: FormActionTypes.SET_REGULATIONS,
                        payload: { stateRegulation: metaKeys[1] },
                    });
                }
            });
        });
    }

    function initConditionalLogic() {
        // const formId = parseInt(getFromStorage("local", "formId"));
        let tempObj: { [codeName: string]: FieldConditionalLogic } = {};
        formState.formResponse?.steps?.forEach((step) => {
            step.fields.forEach((field) => {
                if (
                    field.conditionalLogic &&
                    field.conditionalLogic?.isActive
                ) {
                    tempObj = {
                        ...tempObj,
                        [field.origin.codeName]: {
                            ...field.conditionalLogic,
                            show: shouldShow(field),
                        },
                    };
                    addFieldToFormState({
                        codeName: field.origin.codeName,
                        formId: formState.formId,
                        value: "",
                    });
                }
            });
        });
        setConditionalLogic({ [formState.formId]: tempObj });
    }

    function shouldShow(field: Field): boolean {
        return (
            form?.[formState.formId]?.data?.[
                field.conditionalLogic!.conditions[0].codeName
            ]?.value === field?.conditionalLogic!.conditions[0]?.value
        );
    }

    const hasTCPA = useMemo(() => {
        return formState.formResponse?.steps[formState.currentStep]
            ?.includeTCPAMessage;
    }, [formState?.currentStep]);

    useEffect(() => {
        // empty array on initial
        fieldsThatSkippedValidations.splice(
            0,
            fieldsThatSkippedValidations.length,
        );
        dispatch({
            type: FormActionTypes.SET_IS_LOADING,
            payload: true,
        });
        void fetchOffers();
        setFromJson(
            (formData?.domainForm?.form as DomainForm) ??
                data?.domainForm?.form,
        );
        dispatch({
            type: FormActionTypes.INIT_FORM,
            payload: {
                isLoading: false,
                showThankYou: false,
                currentStep: formState.showBuyersList
                    ? formState.formResponse!.steps.length - 1
                    : formState.currentStep,
                errors: {},
                formResponse:
                    (formData?.domainForm?.form as DomainForm) ??
                    data?.domainForm?.form,
                domainFormId:
                    formData?.domainForm?.id ??
                    (data?.domainForm?.id as number),
                formId:
                    (formData?.domainForm?.form?.id as number) ??
                    data?.domainForm?.form?.id,
            },
        });
        saveToStorage(
            "local",
            "formId",
            (
                (formData?.domainForm?.form?.id as number) ??
                (data?.domainForm?.form?.id as number)
            )?.toString(),
        );
        setShowOtp(false);
        dispatch({
            type: FormActionTypes.SET_IS_LOADING,
            payload: false,
        });
        dispatch({
            type: FormActionTypes.IS_ONE_TO_ONE_CONSENT,
            payload: formState.isOneToOneConsent
                ? formState.isOneToOneConsent
                : isOneToOneConsent || false,
        });

        window.addEventListener("beforeunload", callBackSubmit);

        return () => {
            window.removeEventListener("beforeunload", callBackSubmit);
        };
    }, [formState.modalVisibility]);

    useEffect(() => {
        initExpandableFields();
        initConditionalLogic();
        initDependencies();
    }, [formState.formId]);

    const nextStep = useCallback(async () => {
        if (!getAccessToken() || getAccessToken() === "") {
            window.location.reload();
        }
        if (!isLastStep(formState.currentStep)) {
            // to validate first step when form is split
            if (isFirstStep(formState.currentStep)) {
                dispatch({
                    type: FormActionTypes.SET_BUTTON_LOADING,
                    payload: true,
                });
                const formFromStorage = getFromStorage("local", "form").length
                    ? getFromStorage("local", "form")
                    : "null";
                const tempForm = JSON.parse(formFromStorage) as AllForms;
                let errorsObject: FormErrors | null = null;
                if (tempForm) {
                    errorsObject = await stepValidator(
                        formState.formResponse?.steps[formState.currentStep]
                            ?.fields as Field[],
                        Object.keys(form).length === 0 ? tempForm : form,
                        conditionalLogic,
                        formState.fieldRegulations,
                        formState.formId,
                    );
                    if (errorsObject) {
                        dispatch({
                            type: FormActionTypes.SET_ERRORS,
                            payload: errorsObject,
                        });
                    }
                } else {
                    dispatch({
                        type: FormActionTypes.SET_BUTTON_LOADING,
                        payload: false,
                    });
                    const error = {
                        [formState.formResponse?.steps[0].fields[0].origin
                            .codeName as string]: {
                            codeName: formState.formResponse?.steps[0].fields[0]
                                .origin.codeName as string,
                            message:
                                formState.formResponse?.steps[0].fields[0]
                                    .errorMessage ??
                                formState.formResponse?.steps[0].fields[0]
                                    .origin.errorMessage ??
                                "field is required",
                        },
                    };
                    dispatch({
                        type: FormActionTypes.SET_ERRORS,
                        payload: error,
                    });
                    return;
                }
                dispatch({
                    type: FormActionTypes.SET_BUTTON_LOADING,
                    payload: false,
                });
                if (errorsObject && Object.keys(errorsObject).length) {
                    return;
                } else {
                    dispatch({
                        type: FormActionTypes.SET_MODAL_VISIBILITY,
                        payload: true,
                    });
                }
            }
        }
        dispatch({
            type: FormActionTypes.SET_STEP,
            payload: formState.currentStep + 1,
        });
    }, [formState.currentStep]);

    const getClicksOffers = async () => {
        const formFromStorage = JSON.parse(
            getFromStorage("local", "form").length
                ? getFromStorage("local", "form")
                : "null",
        ) as AllForms;
        if (formFromStorage) {
            const zipCodeField =
                formFromStorage[formState.formId]?.data["zipCode"]?.value;
            if (zipCodeField) {
                dispatch({
                    type: FormActionTypes.SET_OFFERS_CLICK_DATA,
                    payload: {
                        offerClicksLoading: true,
                        listRequestId: "",
                        offerClicks: [],
                    },
                });
                const { data: clicksSearchList } = await getClicksSearchList({
                    questions: {
                        zipCode: {
                            value: zipCodeField as string,
                            name: "zipCode",
                        },
                    },
                    categorySlug: "auto-insurance",
                    crossSellingDomainFormId:
                        crossSelling.data?.domain?.domainCategories?.[0]?.crossSellingDomainFormId?.toString() ??
                        crossSellingProp?.domain?.domainCategories?.[0]?.crossSellingDomainFormId?.toString(),
                });
                dispatch({
                    type: FormActionTypes.SET_OFFERS_CLICK_DATA,
                    payload: {
                        offerClicksLoading: false,
                        listRequestId:
                            clicksSearchList?.listRequestId as string,
                        offerClicks: clicksSearchList?.result as ClickOffer[],
                    },
                });
            }
        }
    };

    const prevStep = useCallback(() => {
        if (!isFirstStep(formState.currentStep)) {
            if (formState.showGenericErrorMessage) {
                dispatch({
                    type: FormActionTypes.SET_GENERIC_ERROR_MESSAGE,
                    payload: null,
                });
                return;
            }
            dispatch({
                type: FormActionTypes.SET_STEP,
                payload: formState.currentStep - 1,
            });
        }
    }, [formState.currentStep, formState.showGenericErrorMessage]);

    function isLastStep(currentStep: number) {
        if (formState.formResponse)
            return currentStep === formState.formResponse?.steps?.length - 1;
    }

    function isFirstStep(currentStep: number): boolean {
        return currentStep === 0;
    }

    if (!formState.formResponse) {
        return <></>;
    }

    const fetchOne2OneConsentBuyers = (operationId: string) => {
        if (formState.isOneToOneConsent) {
            void fetchLeadBuyers(operationId).then(({ data }) => {
                if (data?.buyers.length === 0 || !data) {
                    return;
                } else {
                    if (data.operationKey === "DOMAIN_FORM_BUYERS") {
                        dispatch({
                            type: FormActionTypes.SET_FIRST_ROUND_BUYERS,
                            payload: [
                                ...data.buyers.map((buyer) => ({
                                    ...buyer,
                                    selected: true,
                                })),
                                domainAsBuyer,
                            ],
                        });
                    } else {
                        dispatch({
                            type: FormActionTypes.SET_SECOND_ROUND_BUYERS,
                            payload: [
                                ...data.buyers.map((buyer) => ({
                                    ...buyer,
                                    selected: true,
                                })),
                                domainAsBuyer,
                            ],
                        });
                    }
                }
            });
        }
    };

    const startBidding = async (operationId: string) => {
        if (operationId && formState.isOneToOneConsent) {
            dispatch({
                type: FormActionTypes.SET_BUTTON_LOADING,
                payload: true,
            });
            dispatch({
                type: FormActionTypes.SET_API_CALL_IN_PROGRESS,
                payload: true,
            });
            const { data } = await fetchLeadBuyers(operationId);

            if (
                ((data?.status === "FAILED" && data.buyers.length === 0) ||
                    data?.status === "SUCCESS") &&
                formState.tempSecondRoundBuyers.length === 0
            ) {
                return false;
            }

            if (
                formState.tempSecondRoundBuyers.length > 0 &&
                formState.secondRoundBuyers.length > 0
            ) {
                return false;
            }

            dispatch({
                type: FormActionTypes.SET_API_CALL_IN_PROGRESS,
                payload: false,
            });
            dispatch({
                type: FormActionTypes.SET_SECOND_ROUND_BUYERS,
                payload: data!.buyers.map((item) => ({
                    ...item,
                    selected: true,
                })),
            });
            dispatch({
                type: FormActionTypes.SET_BUTTON_LOADING,
                payload: false,
            });
            dispatch({
                type: FormActionTypes.SET_STEP,
                payload: formState.formResponse!.steps.length - 1,
            });
            dispatch({
                type: FormActionTypes.SET_ERRORS_AFTER_SUBMIT_DATA,
                payload: {
                    buttonLoading: false,
                    showInvalidFieldsFrom: false,
                    showThankYou: false,
                    allErrorsField: [],
                },
            });
            // go to buyers list
            return true;
        }
    };

    async function submitLead(
        fromBlur: boolean = false,
        errors: FormErrors | null = null,
    ): Promise<{
        data: SubmitLeadData | null;
        error: Error | null;
        goToBuyers?: boolean;
    }> {
        let state = form;
        dispatch({
            type: FormActionTypes.SET_BUTTON_LOADING,
            payload: (true && !fromBlur) || formState.showInvalidFieldsFrom,
        });
        if (!form || !Object.values(form).length) {
            const formFromStorage = getFromStorage("local", "form");
            if (formFromStorage && formFromStorage.length) {
                const decodedForm = JSON.parse(formFromStorage) as AllForms;
                state = decodedForm;
            }
        }
        if (
            (!formState.apiCallInProgress ||
                isLastStep(formState.currentStep) ||
                fromBlur) &&
            state &&
            state[formState.formId] &&
            state[formState.formId]?.data
        ) {
            dispatch({
                type: FormActionTypes.SET_API_CALL_IN_PROGRESS,
                payload: true,
            });
            const meta = getUserMeta();

            const fieldsThatHaveValue: { codeName: string; value: string }[] =
                Object.values(state?.[formState.formId]?.data || {})
                    .filter(
                        // TODO: send only the visited steps
                        (item) => item.value && item.value !== "", // we can't only validate visited fields => form state doesn't contain exactly the amount of steps, it has more .. so need to figure out a way to send only the visited steps
                    )
                    .map((field) => {
                        return {
                            codeName: field?.codeName,
                            value: field.value as string,
                        };
                    });

            const stepToStartSendingData =
                formState.formResponse?.steps.filter((step) => {
                    return (
                        step.id === formState.formResponse?.stepToSendingData
                    );
                })[0]?.order ?? 0;

            let stepToStartfetchingBuyers =
                formState.formResponse?.steps.filter((step) => {
                    return (
                        step.id === formState.formResponse?.stepToFetchBuyers
                    );
                })[0]?.order ?? stepToStartSendingData;

            if (stepToStartfetchingBuyers < stepToStartSendingData) {
                stepToStartfetchingBuyers = stepToStartSendingData;
            }

            const fetchBuyers =
                formState.currentStep === stepToStartfetchingBuyers - 1;

            let shouldSubmit = null;

            if (fromBlur || Object.keys(errors ?? {}).length > 0)
                shouldSubmit = false;
            else if (
                formState.showInvalidFieldsFrom ||
                formState.showBuyersList ||
                isLastStep(formState.currentStep)
            )
                shouldSubmit = true;

            if (formState.showInvalidFieldsFrom) {
                shouldSubmit = true;
            }

            const tcpaMessage =
                document.getElementById("tcpa-message")?.innerHTML;

            // only send buyers that are not in the first round
            const buyerIds = formState.showBuyersList
                ? formState.secondRoundBuyers
                      .filter((item) => item.selected)
                      .map((item) => item.buyerId)
                : formState.firstRoundBuyers
                      .filter((item) => item.selected)
                      .map((item) => item.buyerId);

            if (
                stepToStartSendingData - 1 <= formState.currentStep ||
                formState.showInvalidFieldsFrom
            ) {
                const { data, error } = await submitLeadForm({
                    fields: fieldsThatHaveValue,
                    leadId: formState.leadId,
                    jornayaId:
                        (
                            document?.getElementById(
                                "leadid_token",
                            ) as HTMLInputElement | null
                        )?.value ?? undefined,
                    url: window.location.href,
                    submit:
                        typeof shouldSubmit === "boolean"
                            ? shouldSubmit
                            : false,
                    domainFormId: formState.domainFormId,
                    fetchBuyers,
                    otpCode: otpCode || undefined,
                    language: "en",
                    isOneToOneConsent: formState.isOneToOneConsent,
                    ...(shouldSubmit
                        ? {
                              buyerIds: buyerIds.filter(
                                  (item) =>
                                      item !==
                                      `${domainInfo?.domain.id as number}`,
                              ),
                              consentLanguage: tcpaMessage,
                          }
                        : {}),
                    //@ts-ignore
                    meta: {
                        ...meta,
                        ...(shouldSubmit
                            ? {
                                  consent: true,
                                  hasDomainConsent: buyerIds.some(
                                      (item) => item === domainAsBuyer.buyerId,
                                  ),
                              }
                            : {}),
                        skippedValidationFields:
                            fieldsThatSkippedValidations.join(","),
                    },
                });

                if (
                    (shouldSubmit &&
                        formState.isOneToOneConsent &&
                        data?.operationId &&
                        formState.secondRoundBuyers.length === 0) ||
                    (formState.showInvalidFieldsFrom && data && !error)
                ) {
                    const goToBuyersList = await startBidding(
                        data.operationId as string,
                    );
                    if (goToBuyersList) {
                        dispatch({
                            type: FormActionTypes.SET_SHOW_BUYERS_LIST,
                            payload: true,
                        });
                        dispatch({
                            type: FormActionTypes.SET_BUTTON_LOADING,
                            payload: false,
                        });
                        dispatch({
                            type: FormActionTypes.SET_API_CALL_IN_PROGRESS,
                            payload: false,
                        });
                        dispatch({
                            type: FormActionTypes.SET_SHOW_INVALID_FIELDS,
                            payload: false,
                        });
                        return {
                            data: null,
                            error: null,
                            goToBuyers: true,
                        };
                    }
                }
                dispatch({
                    type: FormActionTypes.SET_LEAD_ID,
                    payload: data?.leadId as string,
                });
                if (
                    fetchBuyers &&
                    data?.operationId &&
                    formState.isOneToOneConsent
                ) {
                    dispatch({
                        type: FormActionTypes.SET_OPERATION_ID,
                        payload: data?.operationId,
                    });
                    dispatch({
                        type: FormActionTypes.SET_SHOW_INVALID_FIELDS,
                        payload: false,
                    });
                    void fetchOne2OneConsentBuyers(data?.operationId);
                }
                if (!error && data && formState.showInvalidFieldsFrom) {
                    dispatch({
                        type: FormActionTypes.SET_THANK_YOU_DATA,
                        payload: {
                            leadId: "",
                            showInvalidFieldsFrom: false,
                            showOtpError: false,
                            showThankYou: true,
                            currentStep: 0,
                            buttonLoading: false,
                        },
                    });

                    dispatch({
                        type: FormActionTypes.REMOVE_ALL_ERRORS_FIELDS,
                        payload: [],
                    });
                    dispatch({
                        type: FormActionTypes.SET_BUTTON_LOADING,
                        payload: false,
                    });

                    return { data: null, error: null };
                }
                dispatch({
                    type: FormActionTypes.SET_BUTTON_LOADING,
                    payload: false,
                });
                dispatch({
                    type: FormActionTypes.SET_API_CALL_IN_PROGRESS,
                    payload: false,
                });
                return { data, error };
            }
            dispatch({
                type: FormActionTypes.SET_API_CALL_IN_PROGRESS,
                payload: false,
            });
        }
        dispatch({
            type: FormActionTypes.SET_BUTTON_LOADING,
            payload: false,
        });
        return { data: null, error: null };
    }

    const onSubmit = async () => {
        dispatch({
            type: FormActionTypes.SET_BUTTON_LOADING,
            payload: true,
        });
        let fieldsToBeValidated: FormStep[] | undefined = [];
        if (formState.allErrorsField && formState.allErrorsField.length > 0) {
            for (let i = 0; i < formState.allErrorsField.length; i++) {
                fieldsToBeValidated = formState.formResponse?.steps.filter(
                    (item) =>
                        item.fields.find((item) =>
                            formState.allErrorsField.find(
                                (codename) =>
                                    codename.codeName === item.origin.codeName,
                            ),
                        ),
                );
            }
        }
        // validate either form that's created after submit or the actual form
        const errorsObject: FormErrors | null = await stepValidator(
            fieldsToBeValidated && fieldsToBeValidated?.length > 0
                ? (fieldsToBeValidated?.map(
                      (item) => item.fields,
                  ) as unknown as Field[])
                : formState.formResponse!.steps[formState.currentStep]?.fields,
            form,
            conditionalLogic,
            formState.fieldRegulations,
            formState.formId,
        );
        // if field validation faild -> we have multiple scenario
        // 1- validation api faild -> we need to send fields that faild to validate to backend
        // 2- field validation failed -> show error on ui
        if (errorsObject && Object.keys(errorsObject ?? {}).length) {
            const errorsArray = Object.values(errorsObject);
            const fieldsSkippedValidation = errorsArray.filter(
                (item) => item?.message === "VALIDATION_SKIPPED",
            );
            // scenario 1
            if (fieldsSkippedValidation.length) {
                fieldsSkippedValidation.forEach((item) => {
                    if (
                        item?.codeName &&
                        !fieldsThatSkippedValidations.includes(item.codeName)
                    )
                        fieldsThatSkippedValidations.push(item.codeName);
                });
                dispatch({
                    type: FormActionTypes.SET_STEP,
                    payload: formState.currentStep + 1,
                });
                dispatch({ type: FormActionTypes.SET_ERRORS, payload: {} });
                // scenario 2
            } else {
                if (errorsObject?.zipCode?.type === "regulations") {
                    dispatch({
                        type: FormActionTypes.SET_GENERIC_ERROR_MESSAGE,
                        payload: {
                            title: "Hey Californians!",
                            body: "Your state restricts extended auto warranties. You can get complete coverage by looking into mechanical breakdown insurance (MBI). Visit these websites to find out more:",
                        },
                    });
                    void getClicksOffers();
                }
                dispatch({
                    type: FormActionTypes.SET_ERRORS,
                    payload: errorsObject ?? {},
                });
            }
            dispatch({
                type: FormActionTypes.SET_BUTTON_LOADING,
                payload: false,
            });
            if (!isLastStep(formState.currentStep)) return;
        }
        const { data, error, goToBuyers } = await submitLead(
            false,
            errorsObject && Object.keys(errorsObject).length > 0
                ? errorsObject
                : null,
        );
        if (data || error) {
            if (
                (!data || error) &&
                Object.keys(errorsObject ?? {}).length === 0
            ) {
                //@ts-ignore
                const errorFields = error?.response.data?.data
                    ?.results as ErrorsAfterSubmit[];
                // @ts-ignore
                if (data?.errorCode === "INVALID_LEAD_VALIDATION") {
                    dispatch({
                        type: FormActionTypes.SET_ERRORS_AFTER_SUBMIT_DATA,
                        payload: {
                            buttonLoading: false,
                            showInvalidFieldsFrom: true,
                            showThankYou: false,
                            allErrorsField: errorFields,
                        },
                    });
                } else {
                    dispatch({
                        type: FormActionTypes.SET_ERRORS_AFTER_SUBMIT_DATA,
                        payload: {
                            buttonLoading: false,
                            showInvalidFieldsFrom:
                                formState.showInvalidFieldsFrom,
                            showThankYou: formState.showThankYou,
                            allErrorsField: errorFields,
                        },
                    });
                    return;
                }
            }

            if (data && data.leadId) {
                dispatch({
                    type: FormActionTypes.SET_LEAD_ID,
                    payload: data.leadId,
                });
            }

            if (data?.otpRequired) {
                dispatch({
                    type: FormActionTypes.SET_OTP_DATA,
                    payload: {
                        originalTimer: data?.otpTime as number,
                        buttunLoading: false,
                        showOtp: true,
                        showInvalidFieldsFrom: false,
                        showThankYou: false,
                    },
                });
                return;
            }

            // show thank you
            if (
                data &&
                (!errorsObject ||
                    Object.keys(errorsObject ?? {}).length === 0) &&
                (formState.showInvalidFieldsFrom ||
                    isLastStep(formState.currentStep)) &&
                !error
            ) {
                dispatch({
                    type: FormActionTypes.SET_THANK_YOU_DATA,
                    payload: {
                        leadId: "",
                        showOtpError: false,
                        showInvalidFieldsFrom: false,
                        showThankYou: true,
                        currentStep: 0,
                        buttonLoading: false,
                    },
                });
            }
        }
        if (goToBuyers) {
            return;
        }
        if (!errorsObject || !Object.keys(errorsObject).length) {
            dispatch({
                type: FormActionTypes.SET_BUTTON_LOADING,
                payload: false,
            });
            await nextStep();
        }
    };

    async function fetchOffers() {
        if (
            (selectedCategory && selectedCategory.slug && categoryId) ||
            categorySlug.data
        ) {
            const res = await fetchThankOffersQuery(
                categoryId as number,
                locale as string,
                domainInfo?.domain.id as number,
            );
            dispatch({
                type: FormActionTypes.SET_THANK_YOU_OFFERS,
                payload: (res as GQLOffers[]) ?? [],
            });
        }
    }

    const showBackBtn = (): boolean =>
        formState.currentStep >= formState.stepToShowBackBtn &&
        !formState.showBuyersList;

    const ErrorMessage = () => {
        return (
            <div className="flex flex-col items-center w-full h-fit bg-[#fff6f6] text-[#ab6261] p-4 rounded-[10px] border border-solid border-[#ab6261] mb-5">
                <h1 className="text-[16px] sm:text-[21px] font-bold">
                    {formState.showGenericErrorMessage?.title}
                </h1>
                <p className="text-[11px] sm:text-[17px]">
                    {formState.showGenericErrorMessage?.body}
                </p>
            </div>
        );
    };

    async function callBackSubmit() {
        if (submitLead) await submitLead(false);
    }

    const setError = (name: string, error: ErrorField | null) => {
        const newErrors = {
            ...formState.formErrors,
            [name]: error,
        };
        dispatch({
            type: FormActionTypes.SET_ERRORS,
            payload: { ...newErrors },
        });
    };

    const removeFormDependencies = () => {
        const loadedData = getFromStorage("local", "dependencies");
        if (loadedData && loadedData.length) {
            const parsedLoadedData: dependenciesFromStorage = JSON.parse(
                loadedData,
            ) as dependenciesFromStorage;

            if (parsedLoadedData && parsedLoadedData[formState.formId]) {
                delete parsedLoadedData[formState.formId];
            }
            saveToStorage(
                "local",
                "dependencies",
                JSON.stringify(parsedLoadedData),
            );
        }
    };

    return formState.formResponse &&
        formState.formResponse?.steps &&
        formState.formResponse?.steps.length &&
        !formState.visitorInfo?.isBot &&
        getAccessToken() &&
        getAccessToken() !== "" ? (
        <div
            className="w-full flex justify-center items-center my-12 relative"
            id="form-wrapper-id"
        >
            {jornayaIdElement}
            {formState.isLoading ? (
                <div className="flex justify-center items-center min-h-[200px]">
                    <Spinner size={30} color="#107fd4" success={false} />
                </div>
            ) : (
                <>
                    <FormMainLabel />
                    <div
                        className={`h-full px-4 bg-[#F2F8FF] ${styles["formWrapper"]} relative`}
                        data-tf-element-role="offer"
                    >
                        <div>
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className={`h-full flex w-full z-0 ${
                                    formState.showThankYou
                                        ? "overflow-auto contents w-full"
                                        : "relative justify-center items-center"
                                }`}
                            >
                                {formState.showThankYou &&
                                !formState.modalVisibility ? (
                                    <ThankYou
                                        message={
                                            formData?.domainForm?.form
                                                ?.successMessage ??
                                            formState.formResponse
                                                ?.successMessage
                                        }
                                        formId={formState.formId} // form id is sent to be removed from local storage and formState
                                        shouldRemoveForm={true}
                                        thankYouOffers={
                                            formState.thankYouOffers
                                        }
                                    />
                                ) : formState.showBuyersList &&
                                  !formState.showInvalidFieldsFrom ? (
                                    <SecondRoundBuyers
                                        buyers={formState.secondRoundBuyers}
                                        buttonLoading={formState.buttonLoading}
                                        btnText={
                                            formState.formResponse?.steps[
                                                formState.currentStep
                                            ]?.buttonText
                                        }
                                    />
                                ) : (
                                    <div className="max-w-[600px] m-auto text-center">
                                        <Step
                                            step={
                                                formState.formResponse.steps[0]
                                            }
                                            key={
                                                formState.formResponse.steps[0]
                                                    .id
                                            }
                                            register={register}
                                            setValue={setValue}
                                            watch={watch}
                                            control={control}
                                            errors={errors}
                                            stepNumber={0}
                                            action={onSubmit}
                                            currentStep={0}
                                            stepsLength={
                                                formState.formResponse?.steps
                                                    .length
                                            }
                                            formId={formState.formId}
                                            buttonLoading={
                                                formState.buttonLoading
                                            }
                                            inputAction={submitLead}
                                            formErrors={formState.formErrors}
                                            setError={setError}
                                            firstRoundBuyers={
                                                formState.firstRoundBuyers
                                            }
                                        />
                                    </div>
                                )}
                            </form>
                        </div>
                        {formState.modalVisibility && (
                            <Portal>
                                {formState.isLoading ? (
                                    <div className="flex justify-center items-center min-h-[200px]">
                                        <Spinner
                                            size={30}
                                            color="#107fd4"
                                            success={false}
                                        />
                                    </div>
                                ) : (
                                    <FormModal
                                        className="lg:w-[40%] w-full"
                                        onModalClose={() => {
                                            // clearDataDependency();
                                            // clearExpandableFields();
                                            dispatch({
                                                type: FormActionTypes.CLOSE_FORM_MODAL,
                                                payload: {
                                                    currentStep: 0,
                                                    tcpaMessage: "",
                                                    leadId: formState.showBuyersList
                                                        ? formState.leadId
                                                        : "",
                                                    errors: {},
                                                    stepToShowBackBtn: 2,
                                                },
                                            });
                                            setSelectedCategory(null);
                                            formData = null;
                                            if (shouldSplit) {
                                                dispatch({
                                                    type: FormActionTypes.SET_SHOW_THANK_YOU,
                                                    payload: false,
                                                });
                                            }
                                            if (formState.showThankYou) {
                                                dispatch({
                                                    type: FormActionTypes.SET_SHOW_BUYERS_LIST,
                                                    payload: false,
                                                });
                                                dispatch({
                                                    type: FormActionTypes.SET_FIRST_ROUND_BUYERS,
                                                    payload: [],
                                                });
                                                dispatch({
                                                    type: FormActionTypes.SET_SECOND_ROUND_BUYERS,
                                                    payload: [],
                                                });
                                                dispatch({
                                                    type: FormActionTypes.SET_TEMP_SECOND_ROUND_BUYERS,
                                                    payload: [],
                                                });
                                                removeFormDependencies();
                                                removeForm(formState.formId);
                                                setSelectedCategory(null);
                                            }
                                        }}
                                    >
                                        <form
                                            onSubmit={handleSubmit(onSubmit)}
                                            className={`relative h-full flex justify-center items-center w-full z-0 ${
                                                formState.showThankYou
                                                    ? "overflow-auto contents w-full"
                                                    : "relative justify-center items-center"
                                            }`}
                                        >
                                            {!formState.showInvalidFieldsFrom ? (
                                                <div
                                                    className={`w-[90%] ${
                                                        formState.fieldRegulations &&
                                                        formState.showGenericErrorMessage
                                                            ? "sm:w-[90%]"
                                                            : "sm:w-[70%]"
                                                    } max-h-[550px] sm:max-h-full`}
                                                >
                                                    {formState.showOtp ? (
                                                        <Fade>
                                                            <Otp
                                                                timer={timer}
                                                                setTimer={
                                                                    setTimer
                                                                }
                                                                originalTime={
                                                                    formState.originalTimer
                                                                }
                                                                showOtpError={
                                                                    formState.showOtpError
                                                                }
                                                                setShowOtpError={(
                                                                    showOtp,
                                                                ) => {
                                                                    dispatch({
                                                                        type: FormActionTypes.SET_SHOW_OTP_ERROR,
                                                                        payload:
                                                                            showOtp,
                                                                    });
                                                                }}
                                                            />
                                                        </Fade>
                                                    ) : formState.showThankYou ? (
                                                        <Fade>
                                                            <ThankYou
                                                                message={
                                                                    formData
                                                                        ?.domainForm
                                                                        ?.form
                                                                        ?.successMessage ??
                                                                    formState
                                                                        .formResponse
                                                                        ?.successMessage
                                                                }
                                                                formId={
                                                                    formState.formId
                                                                } // form id is sent to be removed from local storage and formState
                                                                shouldRemoveForm={
                                                                    false
                                                                }
                                                                thankYouOffers={
                                                                    formState.thankYouOffers
                                                                }
                                                            />
                                                        </Fade>
                                                    ) : (
                                                        <div>
                                                            {formState.showGenericErrorMessage &&
                                                                (formState.offerClicksLoading ? (
                                                                    <div className="flex justify-center items-center min-h-[200px]">
                                                                        <Spinner
                                                                            size={
                                                                                30
                                                                            }
                                                                            color="#107fd4"
                                                                            success={
                                                                                false
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <ErrorMessage />
                                                                        {formState.fieldRegulations &&
                                                                            formState.offerClicks
                                                                                ?.slice(
                                                                                    0,
                                                                                    2,
                                                                                )
                                                                                ?.map(
                                                                                    (
                                                                                        clickOffer,
                                                                                        index,
                                                                                    ) => (
                                                                                        <InsuranceOffer
                                                                                            key={
                                                                                                index
                                                                                            }
                                                                                            index={
                                                                                                index
                                                                                            }
                                                                                            item={{
                                                                                                ...clickOffer,
                                                                                                slug: `${clickOffer.slug}?id=${formState.listRequestId}`,
                                                                                            }}
                                                                                            category="Auto Insurance"
                                                                                            state={
                                                                                                ""
                                                                                            }
                                                                                            primaryColor="rgb(22, 147, 240)"
                                                                                            textColor="#266199"
                                                                                        />
                                                                                    ),
                                                                                )}
                                                                    </>
                                                                ))}
                                                            {formState.showBuyersList &&
                                                            !formState.showInvalidFieldsFrom ? (
                                                                <SecondRoundBuyers
                                                                    buyers={
                                                                        formState.secondRoundBuyers
                                                                    }
                                                                    buttonLoading={
                                                                        formState.buttonLoading
                                                                    }
                                                                    btnText={
                                                                        formState
                                                                            .formResponse
                                                                            ?.steps[
                                                                            formState
                                                                                .currentStep
                                                                        ]
                                                                            ?.buttonText
                                                                    }
                                                                />
                                                            ) : (
                                                                !formState.showGenericErrorMessage &&
                                                                formState.formResponse?.steps.map(
                                                                    (
                                                                        step: FormStep,
                                                                        index: number,
                                                                    ) => (
                                                                        <Step
                                                                            step={
                                                                                step
                                                                            }
                                                                            key={
                                                                                step.id
                                                                            }
                                                                            register={
                                                                                register
                                                                            }
                                                                            setValue={
                                                                                setValue
                                                                            }
                                                                            watch={
                                                                                watch
                                                                            }
                                                                            control={
                                                                                control
                                                                            }
                                                                            errors={
                                                                                errors
                                                                            }
                                                                            stepNumber={
                                                                                index
                                                                            }
                                                                            currentStep={
                                                                                formState.currentStep
                                                                            }
                                                                            action={
                                                                                onSubmit
                                                                            }
                                                                            stepsLength={
                                                                                formState
                                                                                    .formResponse
                                                                                    ?.steps
                                                                                    .length ??
                                                                                0
                                                                            }
                                                                            formId={
                                                                                formState.formId
                                                                            }
                                                                            buttonLoading={
                                                                                formState.buttonLoading
                                                                            }
                                                                            inputAction={
                                                                                submitLead
                                                                            }
                                                                            isSingleField={
                                                                                step
                                                                                    .fields
                                                                                    .length ===
                                                                                1
                                                                            }
                                                                            formErrors={
                                                                                formState.formErrors
                                                                            }
                                                                            setError={
                                                                                setError
                                                                            }
                                                                            isLastStep={isLastStep(
                                                                                formState.currentStep,
                                                                            )}
                                                                            firstRoundBuyers={
                                                                                formState.firstRoundBuyers
                                                                            }
                                                                        />
                                                                    ),
                                                                )
                                                            )}
                                                            {((formState.formResponse &&
                                                                !isLastStep(
                                                                    formState.currentStep,
                                                                ) &&
                                                                !isFirstStep(
                                                                    formState.currentStep,
                                                                )) ||
                                                                shouldSplit) &&
                                                                !formState.showBuyersList && (
                                                                    <ProgressBar
                                                                        className={`z-[-1]`}
                                                                        total={
                                                                            formState
                                                                                .formResponse
                                                                                ?.steps
                                                                                ?.length ??
                                                                            0
                                                                        }
                                                                        current={
                                                                            formState.currentStep
                                                                        }
                                                                        color={
                                                                            "#1693f0"
                                                                        }
                                                                    />
                                                                )}
                                                            {hasTCPA &&
                                                                (formState.showBuyersList ? (
                                                                    <SecondRoundTCPA
                                                                        // buyers={
                                                                        //     formState.secondRoundBuyers
                                                                        // }
                                                                        tcpaMessage={
                                                                            formState.tcpaMessage &&
                                                                            formState
                                                                                .tcpaMessage
                                                                                .length >
                                                                                0
                                                                                ? formState.tcpaMessage
                                                                                : (tcpaMessageReactQuery.data as string)
                                                                        }
                                                                        domainAsBuyer={
                                                                            domainAsBuyer
                                                                        }
                                                                    />
                                                                ) : (
                                                                    <FirstRoundTCPA
                                                                        tcpaMessage={
                                                                            formState.tcpaMessage &&
                                                                            formState
                                                                                .tcpaMessage
                                                                                .length >
                                                                                0
                                                                                ? formState.tcpaMessage
                                                                                : (tcpaMessageReactQuery.data as string)
                                                                        }
                                                                    />
                                                                ))}
                                                            {showBackBtn() && (
                                                                <div
                                                                    className="flex mt-6 pb-3 cursor-pointer w-fit"
                                                                    onClick={
                                                                        prevStep
                                                                    }
                                                                >
                                                                    <img
                                                                        src="https://icons.iconarchive.com/icons/praveen/minimal-outline/256/back-icon.png"
                                                                        alt="back"
                                                                        width={
                                                                            "25px"
                                                                        }
                                                                        height={
                                                                            "15px"
                                                                        }
                                                                    />
                                                                    <p className="text-xs mt-1">
                                                                        back
                                                                    </p>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div className="w-[100%] max-w-[600px] mx-[0.5rem] sm:mx-7">
                                                    <Fade>
                                                        <ErrorsFieldsAfterSubmit
                                                            allErrorsFields={
                                                                formState.allErrorsField
                                                            }
                                                            buttonLoading={
                                                                formState.buttonLoading
                                                            }
                                                            stepsThatHaveErrors={
                                                                stepsOrderThatHaveError
                                                            }
                                                            register={register}
                                                            setValue={setValue}
                                                            watch={watch}
                                                            inputAction={
                                                                submitLead
                                                            }
                                                            formErrors={
                                                                formState.formErrors
                                                            }
                                                            setError={setError}
                                                        />
                                                    </Fade>
                                                </div>
                                            )}
                                        </form>
                                    </FormModal>
                                )}
                            </Portal>
                        )}
                    </div>
                </>
            )}
        </div>
    ) : (
        <div className="flex justify-center items-center min-h-[200px]">
            <Spinner size={30} color="#107fd4" success={false} />
        </div>
    );
}
